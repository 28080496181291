
// JSDoc (http://usejsdoc.org/)
/** @module PlanDidacticoService */

export default class PlanDidacticoService{
    /**
     * @param axios Axios Instance
     */
    constructor(axios){
        this.axios =  axios;
    }
    /**
     * Crear registro inicial del plan didactico.
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.codigo  codigo de plan default 0
     * @param {Integer} data.codplm  codigo de plan materia
     * @param {String} data.ciclo  Nombre de ciclo
     * @param {String} data.problematica  Problematica de planificacion
     * @param {String} data.comp_asignatura  Competencia especifica de asignatura
     * @param {Integer} data.horas_ens  Horas de enseñanza
     * @param {Integer} data.horas_apren  horas de aprendizajes
     * @param {String} data.version  Version planificacion OUT
     * @return {json} JsonObject
     */
    async createPlan(data) {
        return this.axios.post('/PlanDidactico', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

      /**
     * Crear registro inicial del plan didactico.
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.codigo  codigo de plan default 0
     * @param {Integer} data.codplm  codigo de plan materia
     * @param {String} data.nombcil  Nombre de ciclo
     * @param {Integer} data.codcil  Código de ciclo
     * @param {Date} data.fechacil  Fecha de ciclo
     * 
     * @param {Boolean} data.generalidades  Copiar Generalidades
     * @param {Boolean} data.competencias  Copiar Competenecias
     * @param {Boolean} data.recursos  Copiar Recursos
     * @param {Boolean} data.comision  Copiar Comisión
     * @return {json} JsonObject
     */
      async copyPlan(data) {
        return this.axios.post('/PlanDidactico/Copy', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Editar registro inicial del plan didactico.
     * @param {Integer}  Codigo  Codigo de Plan.
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.codigo  codigo de plan
    
     * @param {String} data.problematica  Problematica de planificacion
     * @param {String} data.comp_asignatura  Competencia especifica de asignatura
     * @param {Integer} data.horas_ens  Horas de enseñanza
     * @param {Integer} data.horas_apren  horas de aprendizajes
     * @return {json} JsonObject
     */
     async updatePlan(codigo,data) {
        return this.axios.put(`/PlanDidactico/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }


    /**
     * Obtener plan por codigo
     * @param {Integer} codigo  codigo de plan
     * @param {Integer} coe_codemp  codigo de empleado -> Default 0 (No restricción)
     * @return {json} JsonObject
     */
    async getPlan(codigo,coe_codemp) {
        return this.axios.get(`/PlanDidactico/${codigo}/${coe_codemp}`).then(res => res.data);
    }

    /**
     * Obtener plan por facultad
     * @param {Integer} codigo  codigo de facultad  -> default 0 (todas)
     * @param {Boolean} agrupar  Agrupar por facultad
     * @param {Boolean} notificar  Notificar al extraer
     * @return {json} JsonObject
     */
     async getPlanByFacultad(codigo = 0, agrupar = true, notificar = false) {
        return this.axios.get(`/PlanDidactico/GetByFacultad/${codigo}/${agrupar}/${notificar}`).then(res => res.data);
    }


    /**
     * Obtener plan por facultad
    * @param {Integer} codigo  codigo de empleado  -> default 0 (todos)
     * @param {Boolean} agrupar  Agrupar por facultad
     * @param {Boolean} notificar  Notificar al extraer
     * @return {json} JsonObject
     */
     async getPlanByEmpleado(codigo = 0, agrupar = true, notificar = false) {
        return this.axios.get(`/PlanDidactico/GetByEmpleado/${codigo}/${agrupar}/${notificar}`).then(res => res.data);
    }


    /**
     * Obtener todos los planes del parametro 
     * @param {Integer} codigo  codigo de plan
     * @return {json} JsonObject
     */
    async getPlanes(prms) {
        return this.axios.get(`/PlanDidactico/`,{
            params: {
                page : prms.page,
                pageSize : prms.pageSize,
                fac_codigo :  prms.fac_codigo,
                cil_nombre : prms.cil_nombre,
                car_codigo :  prms.car_codigo,
                coe_codemp :  prms.coe_codemp,
                pld_estado :  prms.pld_estado,
                searchTerm :  prms.searchTerm
            }
        }).then(res => res.data);
    }

    async printPlan(codigo){
        return this.axios({
            url: `/PlanDidactico/Print/${codigo}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((res) =>res);
    }

    /**
     * Agrega una area de formación al plan didáctico.
     * @param {json} data parametros de entrada
     * @param {Integer} data.pdf_codpla Codigo de plan académico
     * @param {Integer} data.pdf_codpaf Codigo de área de formación 
     * @return {json} JsonObject 
     */
     async createAreaFormacion(data){
        return this.axios.post(`/PlanDidactico/CreateAreaForm`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res=> res.data);
    }

    /**
    * Obtener listado de areas de formación de Plan didáctico
    * @param {Integer} codigo  Código de Plan
    * @return {json} JsonObject
    */
    async getAreaFormacion(codigo) {
        return this.axios.get(`/PlanDidactico/GetAreasFormacion/${codigo}/`).then(res => res.data);
    }


     /**
     * Eliminar area de formacion de planifición
     * @param {Integer} codigo Codigo del ara de formación en el plan
     * @return {String} Mensaje de repuesta
     */
    async deleteAreaFormacion(codigo){
        return this.axios.delete(`/PlanDidactico/DeletePlanAreaForm/${codigo}`).then(res => res.data).catch(ex => ex);
    }

    /**
     * Agregar competencia a planificiacion
     * @param {json} data parametros de entrada
     * @param {Integer} data.codigo  Código de plan
     * @param {Integer} data.cod_competencia Código de competencia
     * @return {json} JsonObject
     */
    async createCompetencia(data) {
        var config = {
            method: 'post',
            url: `/PlanDidactico/${data.codigo}/CreateCompetencia/`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data.cod_competencia
          };
        
        return  this.axios(config).then(res => res.data);

     
    }

    /**
     * Obtener competencias de un plan
     * @param {Integer} codigo  codigo de plan
     * @return {json} JsonObject
     */
    async getCompetencias(codigo) {
        return this.axios.get(`/PlanDidactico/${codigo}/GetCompetencias`).then(res => res.data);
    }

    /**
     * Eliminar competencia de planifición
     * @param {Integer} codigo Codigo del recurso en el plan
     * @return {String} Mensaje de repuesta
     */
    async deleteCompetencia(codigo) {
        return this.axios.delete(`/PlanDidactico/DeleteCompetencia/${codigo}`).then(res => res.data).catch(ex => ex);
    }

     /**
     * Agregar fuente a planificacion
     * @param {Integer} codigo  Código de plan
     * @param {json} data parametros de fuente
     * @param {Integer} data.fin_nombre Fuente nombre
     * @param {Integer} data.fin_enlace Fuente enlace
     * @param {Integer} data.fin_codtif Fuente Tipo
     * @return {json} JsonObject
     */
    async createFuente(codigo, data) {
        return  this.axios.post(`/PlanDidactico/${codigo}/CreateFuente/`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

     /**
     * Eliminar fuete del plan didactico
     * @param {Integer} codigo Codigo del recurso en el plan
     * @return {String} Mensaje de repuesta
     */
    async deleteFuente(codigo) {
        return this.axios.delete(`/PlanDidactico/DeleteFuente/${codigo}`).then(res => res.data).catch(ex => ex);
    }


    
    //#region  FUENTES DE INFORMACION 
    /**
     * Obtener fuente de informacion asociadas
     * @param {Integer} codigo  codigo de plan
     * @return {json} JsonObject
     */
    async getFuentes(codigo) {
        return this.axios.get(`/PlanDidactico/${codigo}/GetFuentes`).then(res => res.data);
    }

    /**
     * Obtener Tipos de fuente de informacion
     * @return {json} JsonObject
     */
     async getTiposFuente() {
        return this.axios.get(`/TipoFuente`).then(res => res.data);
    }

    /**
     * Obtener tipo de fuente por código
     * @param {Integer} codigo  codigo de fuente
     * @return {json} JsonObject
     */
     async getTipoFuenteByCode(codigo) {
        return this.axios.get(`/TipoFuente/${codigo}`).then(res => res.data);
    }

//#endregion

//#region "Administracion de Bloques"

    /**
     * Agregar Bloque de programación de la planificación
     * @param {Integer} codigo  Código de plan
     * @param {json} data parametros de bloque
     * @param {String} data.plb_nombre_tec Nombre Tecnico
     * @param {String} data.plb_nombre_est Nombre para estudiante
     * @param {String} data.plb_criterio_eval Criterio
     * @param {String} data.plb_evidencia_logro Evidencia de logro
     * @param {String} data.plb_instrumento_eval Instrumento de Evaluacion
     * @param {Integer} data.plb_codpld Codigo de Plan
     * @return {json} JsonObject
     */
    async createBloque(codigo, data) {
        return  this.axios.post(`/PlanDidactico/${codigo}/CreateBloque/`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

   
    /**
     * Obtener bloques de programación de planificacion
     * @param {Integer} codigo  codigo de plan
     * @return {json} JsonObject
     */
    async getBloques(codigo) {
        return this.axios.get(`/PlanDidactico/${codigo}/GetBloques`).then(res => res.data);
    }

     /**
     * Eliminar un bloque 
     * @param {Integer} Codigo Código del bloque
     * @param {Integer} CodigoPlan Código del bloque
     * @return {String} Mensaje de repuesta
     */
      async deleteBloque(codigo, codigoBloque) {
        return this.axios.delete(`/PlanDidactico/${codigo}/DeleteBloque/${codigoBloque}`).then(res => res.data);
    }

    /**
     * Obtener los tipos de Ejes
     * @return {json} JsonObject
     */
    async getEjes() {
        return this.axios.get(`/PlanDidactico/GetAllEje`).then(res => res.data);
    }

    /**
     * Agregar Bloque de programación de la planificación
     * @param {Integer} codigo  Código de bloque
     * @param {json} data parametros de bloque detalle
     * @param {String} data.pbd_fecha_desde Fecha Hasta
     * @param {String} data.pbd_fecha_hasta Fecha desde
     * @return {json} JsonObjects
     */

    async createBloqueDetalle(codigo, data) {
        return  this.axios.post(`PlanDidactico/Bloque/${codigo}/CreateBloqueDetalle`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }
    /**
     * Obtener los detalles del bloque
     * @return {json} JsonObject
     */
    async getBloqueDetalle(codigo) {
        return this.axios.get(`/PlanDidactico/Bloque/${codigo}/GetBloqueDetalle`).then(res => res.data);
    }

    /**
     * Eliminar un bloque detalle de semana
     * @param {Integer} codigoBloque Código del bloque
     * @param {Integer} codigoDetalle Código del bloque detalle
     * @return {String} Mensaje de repuesta
     */
     async deleteBloqueDetalle(codigoBloque, codigoDetalle) {
        return this.axios.delete(`/PlanDidactico/Bloque/${codigoBloque}/DeleteDetalle/${codigoDetalle}`).then(res => res.data);
    }

    /**
     * Ordenar semanas de detalle
     * @param {Integer} codigoBloque Código del bloque
     * @param {json} dataDetalle dato de parametros de entrada
     * @param {Integer} dataDetalle.pbd_codigo Codigo de detalle
     * @param {Integer} dataDetalle.pbd_semana Numero de Semana
     * @return {String} Mensaje de repuesta
     */
     async orderBloqueDetalle(codigoBloque, dataDetalle) {
        return this.axios.put(`/PlanDidactico/Bloque/${codigoBloque}/OrderDetalle/`, dataDetalle,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

     /**
     * Obtener los detalles del bloque con los correspondientes ejes Tow
     * @param {Integer} codigo  Código de Bloque Detalle
     * @param {Integer} codigoBloqueEje  Código de bloque Eje
     * @return {json} JsonObject
     */
      async getBloqueDetalleEje(codigo, codigoBloqueEje) {
        return this.axios.get(`PlanDidactico/BloqueDetalle/${codigo}/GetBloqueDetalleEje/${codigoBloqueEje}`).then(res => res.data);
    }

    /**
    * Actualiza contenido de bloque eje.
    * @param {json} data Parámetros de Entrada.
    * @param {Integer} data.ejb_codigo Código de bloque Eje
    * @param {String} data.ejb_contenido Contenido del eje
   
    * @return {Integer} Filas afectadas
    */
     async updateBloqueEje(data){
        return this.axios.put(`/PlanDidactico/BloqueEje/Update/${data.ejb_codigo}`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);

       
    }


//#endregion



//#region 'COMISION DE ELABORACION'
    /**
     * Agregar empleados a la comision de elaboracion de plan
     * @param {Integer} codigo  Código de bloque
     * @param {json} data parametros de comision de entrada
     * @param {String} data.emp_codigo Fecha Hasta
     * @param {String} data.emp_puesto Fecha desde
     * @return {Integer} JsonObjects
     */
    async createComision(codigo, data) {
        return  this.axios.post(`PlanDidactico/${codigo}/CreateComision`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

    /**
     * Obtener los empleados dentro de la comisoon
     * @param {Integer} codigo  Código de Plan
     * @return {json} JsonObject
     */
     async getComision(codigo) {
        return this.axios.get(`PlanDidactico/${codigo}/GetComision/`).then(res => res.data);
    }

    /**
     * Eliminar comision de la planificacion
     * @param {Integer} codigo Codigo del empleado comision
     * @return {String} Mensaje de repuesta
     */
     async deleteComision(codigo) {
        return this.axios.delete(`/PlanDidactico/DeleteComision/${codigo}`).then(res => res.data).catch(ex => ex);
    }

    /**
     * Aprobar o Revisar  comision de la planificacion
     * @param {integer} codPlan Codigo de Plan didactico
     * @param {Integer} codEmpl Codigo del empleado comision
     * @return {String} Mensaje de repuesta
     */
    async approveComision(codPlan, codEmpl){

        var config = {
            method: 'patch',
            url: `/PlanDidactico/${codPlan}/ApproveComision/`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : codEmpl
          };
        
        return  this.axios(config).then(res => res.data);
    }

    /**
     * Habilitar comision de la planificacion
     * @param {integer} codPlan Codigo de Plan didactico
     * @param {Integer} codigo Codigo del empleado comision
     * @param {Boolean} todos Habilitar todos
     * @param {String} observacion Notas sobre observacion o Motivo
     * @param {json} empleadoList Lista de empleados a notificar
     * @return {String} Mensaje de repuesta
     */
     async enableComision(codPlan, observacion = "", empleadoList = []){

        var config = {
            method: 'patch',
            url: `/PlanDidactico/${codPlan}/EnableComision/${observacion}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : empleadoList
          };
        
        return  this.axios(config).then(res => res.data);
    }
//#endregion


//#region **** PRACTICA DE PROGRAMACION ****

    /**
     * Obtener la programacion de practivas
     * @param {Integer} codigo  Código de Plan
     * @return {json} JsonObject
     */
    async getPracticas(codigo) {
        return this.axios.get(`PlanDidactico/${codigo}/GetPractica/`).then(res => res.data);
    }


    /**
     * Agregar Practica de programación de la planificación
     * @param {Integer} codigo  Código de plan
     * @param {json} data parametros de practica
     * @param {String} data.prp_total_horas Total de horas
     * @param {String} data.prp_nombre Nombre de practica
     * @param {String} data.prp_lugar Lugar de practica
     * @param {String} data.prp_codemp  Empleado codigo
     * @param {String} data.prp_codigo Codigo de practica
     * @return {json} JsonObject
     */
     async createPractica(codigo, data) {
        return  this.axios.post(`/PlanDidactico/${codigo}/CreatePractica/`,data,{
            headers: { 
                'Content-Type': 'application/json'
            },
        } ).then(res => res.data);
    }

   /**
     * Actualizar Practica de programación de la planificación
     * @param {Integer} codigo  Codigo de Practica
     * @param {json} data parametros de practica
     * @param {String} data.prp_total_horas Total de horas
     * @param {String} data.prp_nombre Nombre de practica
     * @param {String} data.prp_lugar Lugar de practica
     * @param {String} data.prp_codemp  Empleado codigo
     * @param {String} data.prp_codigo Código de practica
     * @return {json} JsonObject
     */
     async updatePractica(codigo,data){
        return this.axios.put(`/PlanDidactico/UpdatePractica/${codigo}`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);

       
    }

    /**
     * Eliminar practica del la planificación
     * @param {Integer} codigo Código de la practica
     * @return {String} Mensaje de repuesta
     */
     async deletePractica(codigo) {
        return this.axios.delete(`/PlanDidactico/DeletePractica/${codigo}`).then(res => res.data);
    }
//#endregion **** PRACTICA DE PROGRAMACION ****



    /**
     * Obtener la programacion de practivas
     * @param {Integer} codigo  Código de Plan
     * @return {json} JsonObject
     */
    async getBloqueNivel(codigo) {
        return this.axios.get(`/PlanDidactico/BloqueNivel/${codigo}/`).then(res => res.data);
    }

    /**
    * Actualiza los niveles de dominio del bloque
    * @param {json} data Parámetros de Entrada.
    * @param {Integer} data.pbn_codigo Código de bloque nivel
    * @param {String} data.pbn_contenido Contenido del bloque nivel
    * @return {Integer} Filas afectadas
    */
     async updateBloqueNivel(codigo, data){
        return this.axios.put(`/PlanDidactico/BloqueNivel/UpdateNivel/${codigo}`,data,{
            headers: 
            {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }


   
}